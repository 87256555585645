<template>
  <div>
    <Manpower_bar />
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="ตั้งค่าระบบอัตรากำลัง"
              class="px-5 py-3 text_google"
            >
            </base-material-card>
            <PeriodCollegeManpowers />

            <v-card-text>
              <v-form ref="editsys_config_collegemanpowerform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md4>
                      <v-switch
                        v-model="
                          sys_config_collegemanpowers.sys_config_CollegemanpowerHumansw
                        "
                        label="เปิดแก้ไขข้อมูลบุคคล"
                        @click="editsys_config_collegemanpowerSubmit()"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-switch
                        v-model="
                          sys_config_collegemanpowers.sys_config_CollegemanpowerStudentsw
                        "
                        label="เปิดแก้ไขข้อมูลนักเรียน"
                        @click="editsys_config_collegemanpowerSubmit()"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-switch
                        v-model="
                          sys_config_collegemanpowers.sys_config_CollegemanpowerCalStDsw
                        "
                        label="เปิดแก้ไขการคำนวณข้อมูลนักเรียน"
                        @click="editsys_config_collegemanpowerSubmit()"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="12">
          <Budget_etc />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        auto-height
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import Budget_etc from "../../components/admin/budget_etc.vue";
import PeriodCollegeManpowers from "../../components/admin/periodCollegeManpowers.vue";
import Manpower_bar from "../../components/admin/manpower_bar.vue";

export default {
  name: "sys_config_collegemanpower",
  data() {
    return {
      VueMoment,
      moment,
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      editsys_config_collegemanpowerdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      sys_config_collegemanpowers: [],
      editsys_config_collegemanpower: {},
      search: "",
      pagination: {},
      data_syslog: {}
    };
  },
  async mounted() {
    this.sys_config_collegemanpowerQueryAll();
  },
  methods: {
    async sys_config_collegemanpowerQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sys_config_collegemanpower.php", {
          ApiKey: this.ApiKey,
          sys_config_CollegemanpowerID: "1"
        })
        .finally(() => (this.loading = false));
      this.sys_config_collegemanpowers = result.data;
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerHuman == true
      ) {
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerHumansw = true;
      } else {
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerHumansw = false;
      }
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerStudent ==
        true
      ) {
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerStudentsw = true;
      } else {
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerStudentsw = false;
      }
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerCalStD ==
        true
      ) {
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerCalStDsw = true;
      } else {
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerCalStDsw = false;
      }
    },
    /*  */
    async editsys_config_collegemanpowerSubmit() {
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerHumansw ==
        true
      )
        this.editsys_config_collegemanpower.sys_config_CollegemanpowerHuman =
          "1";
      else
        this.editsys_config_collegemanpower.sys_config_CollegemanpowerHuman =
          "0";
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerStudentsw ==
        true
      )
        this.editsys_config_collegemanpower.sys_config_CollegemanpowerStudent =
          "1";
      else
        this.editsys_config_collegemanpower.sys_config_CollegemanpowerStudent =
          "0";
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerCalStDsw ==
        true
      )
        this.editsys_config_collegemanpower.sys_config_CollegemanpowerCalStD =
          "1";
      else
        this.editsys_config_collegemanpower.sys_config_CollegemanpowerCalStD =
          "0";
      this.editsys_config_collegemanpower.ApiKey = this.ApiKey;
      this.editsys_config_collegemanpower.sys_config_CollegemanpowerID = "1";
      let result = await this.$http.post(
        "sys_config_collegemanpower.update.php",
        this.editsys_config_collegemanpower
      );
      if (result.data.status == true) {
        this.sys_config_collegemanpower = result.data;
        this.snackbar.icon = "mdi-font-awesome";
        this.snackbar.color = "success";
        this.snackbar.text = "แก้ไขข้อมูลเรียบร้อย";
        this.snackbar.show = true;
        this.sys_config_collegemanpowerQueryAll();
      } else {
        this.snackbar.icon = "mdi-close-network";
        this.snackbar.color = "red";
        this.snackbar.text = "แก้ไขข้อมูลผิดพลาด";
        this.snackbar.show = true;
      }
    }
  },
  computed: {
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { Budget_etc, PeriodCollegeManpowers, Manpower_bar }
};
</script>
