<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ตั้งค่าประเภทงบประมาณ ครูจ้างสอน"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="budget_etcAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="budget_etcs"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="budget_etcEdit(item.budget_etcid)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click.stop="budget_etcDelete(item.budget_etcid)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addbudget_etcdialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addbudget_etcdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลตั้งค่าประเภทงบประมาณเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addbudget_etcform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-text-field
                        label="งบประมาณ/โครงการ"
                        v-model="addbudget_etc.budget_etcdetail"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addbudget_etcdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addbudget_etcSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletebudget_etcdialog -->
      <v-layout>
        <v-dialog v-model="deletebudget_etcdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลตั้งค่าประเภทงบประมาณเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletebudget_etcform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลตั้งค่าประเภทงบประมาณเอก
                          {{ editbudget_etc.budget_etcdatetime }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletebudget_etcdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletebudget_etcSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editbudget_etcdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editbudget_etcdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลตั้งค่าประเภทงบประมาณ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editbudget_etcform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-text-field
                        label="งบประมาณ/โครงการ"
                        v-model="editbudget_etc.budget_etcdetail"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editbudget_etcdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editbudget_etcSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addbudget_etcdialog: false,
      editbudget_etcdialog: false,
      deletebudget_etcdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      budget_etcs: [],
      addbudget_etc: {},
      editbudget_etc: {},
      search: "",
      pagination: {},
      headers: [
        { text: "รหัส", align: "center", value: "budget_etcid" },      
        { text: "งบประมาณ", align: "left", value: "budget_etcdetail" },
        { text: "วันที่ข้อมูล", align: "left", value: "budget_etcdatetime" },

        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
        /*  {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        } */
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      typeBudget: [
        { text: "เงินงบประมาณ", value: "เงินงบประมาณ" },
        { text: "เงินนอกงบประมาณ", value: "เงินนอกงบประมาณ" }
      ]
    };
  },
  async mounted() {
    this.budget_etcQueryAll();
  },
  methods: {
    async budget_etcQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("budget_etc.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.budget_etcs = result.data;
    },
    async budget_etcAdd() {
      this.addbudget_etc = {};
      this.addbudget_etcdialog = true;
    },
    async addbudget_etcSubmit() {
      if (this.$refs.addbudget_etcform.validate()) {
        this.addbudget_etc.ApiKey = this.ApiKey;
        this.addbudget_etc.budget_etcdatetime = new Date();
        let result = await this.$http.post(
          "budget_etc.insert.php",
          this.addbudget_etc
        );
        if (result.data.status == true) {
          this.budget_etc = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.budget_etcQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "บันทึกข้อมูลผิดพลาด";
          this.snackbar.show = true;
          this.budget_etcQueryAll();
        }
        this.addbudget_etcdialog = false;
      }
    },
    async budget_etcEdit(budget_etcid) {
      let result = await this.$http.post("budget_etc.php", {
        ApiKey: this.ApiKey,
        budget_etcid: budget_etcid
      });
      this.editbudget_etc = result.data;
      this.editbudget_etcdialog = true;
    },
    async editbudget_etcSubmit() {
      if (this.$refs.editbudget_etcform.validate()) {
        this.editbudget_etc.ApiKey = this.ApiKey;
        this.editbudget_etc.budget_etcdatetime = new Date();
        let result = await this.$http.post(
          "budget_etc.update.php",
          this.editbudget_etc
        );
        if (result.data.status == true) {
          this.budget_etc = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "แก้ไขข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.budget_etcQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "แก้ไขข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.editbudget_etcdialog = false;
      }
    }
    /*    async budget_etcDelete(budget_etcid) {
      let result = await this.$http.post("budget_etc.php", {
        ApiKey: this.ApiKey,
        budget_etcid: budget_etcid
      });
      this.editbudget_etc = result.data;
      this.deletebudget_etcdialog = true;
    }, */
    /*   async deletebudget_etcSubmit() {
      if (this.$refs.deletebudget_etcform.validate()) {
        this.editbudget_etc.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "budget_etc.delete.php",
          this.editbudget_etc
        );
        if (result.data.status == true) {
          this.budget_etc = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.budget_etcQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletebudget_etcdialog = false;
      }
    } */
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
